var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"}},[_c('van-nav-bar',{staticStyle:{"flex-shrink":"0"},attrs:{"title":"分享","left-arrow":"","fixed":true,"placeholder":""},on:{"click-left":_vm.close},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.upload(99)}}},[_vm._v("保存")])]},proxy:true}])}),_c('div',{staticClass:"pic"},[_c('div',{ref:"imageDom",staticClass:"pic-box",style:(`width: ${_vm.width ? _vm.width : _vm.defaultWidth}px; height:  ${_vm.height ? _vm.height : _vm.defaultHeight
      }px`)},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/img/redPacketRain/rainShare.png"),"alt":""}}),_c('img',{staticClass:"qr",style:(_vm.width
          ? 'width: 40vw; height: 40vw; bottom: 7%'
          : 'width: 110px; height: 110px; bottom: 34px'),attrs:{"src":_vm.qrSrc,"alt":""}}),_c('div',{staticClass:"code",style:(_vm.width
          ? `top: 39%; font-size: ${_vm.codeSize1}px`
          : `top: 180px; font-size: ${_vm.defaultCodeSize1}px`)},[_c('span',[_vm._v(_vm._s(_vm.num))]),_c('span',{style:(_vm.width
            ? `font-size: ${_vm.codeSize2}px`
            : ` font-size: ${_vm.defaultCodeSize2}px`)},[_vm._v(_vm._s(_vm.typeJiangli == '1' ? '驿马' : '现金'))])])]),_c('img',{ref:"imgDom",staticClass:"img-box",staticStyle:{"display":"none"},attrs:{"src":"","alt":""}})]),(_vm.show)?_c('canvas',{style:(`width: ${_vm.width ? _vm.width : _vm.defaultWidth}px`),attrs:{"id":"myCanvas","width":_vm.width ? _vm.width : _vm.defaultWidth,"height":_vm.height ? _vm.height : _vm.defaultHeight}}):_vm._e(),_c('div',{staticStyle:{"height":"130px","flex-shrink":"0"}}),_c('div',{staticClass:"bottom"},[_vm._m(0),_c('div',{staticClass:"bottom-list"},[_c('div',{staticStyle:{"width":"100px"},on:{"click":function($event){return _vm.upload(0)}}},[_c('img',{staticStyle:{"width":"32px"},attrs:{"src":"img/weixin.png","alt":""}}),_c('p',[_vm._v("微信")])]),_c('div',{staticStyle:{"width":"100px"},on:{"click":function($event){return _vm.upload(1)}}},[_c('img',{staticStyle:{"width":"32px"},attrs:{"src":"img/pengyouquan.png","alt":""}}),_c('p',[_vm._v("朋友圈")])]),_c('div',{staticStyle:{"width":"100px"},on:{"click":function($event){return _vm.upload(2)}}},[_c('img',{staticStyle:{"width":"32px"},attrs:{"src":"img/qq.png","alt":""}}),_c('p',[_vm._v("QQ")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title"},[_vm._v("分享图文至")])])
}]

export { render, staticRenderFns }