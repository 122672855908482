<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <!-- 导航 -->
    <van-nav-bar title="分享" left-arrow @click-left="close" :fixed="true" placeholder style="flex-shrink: 0">
      <template #right>
        <span @click="upload(99)">保存</span>
      </template>
    </van-nav-bar>
    <!-- 导航 -->
    <div class="pic">
      <!-- 生成canvas的样本 -->
      <div class="pic-box" ref="imageDom" :style="`width: ${width ? width : defaultWidth}px; height:  ${height ? height : defaultHeight
        }px`">
        <img src="@/assets/img/redPacketRain/rainShare.png" alt=""
          style="width: 100%; height: 100%" />
        <!-- <img class="qr" src="/img/qr.png" alt="" /> -->
        <img class="qr" :src="qrSrc" alt="" :style="width
            ? 'width: 40vw; height: 40vw; bottom: 7%'
            : 'width: 110px; height: 110px; bottom: 34px'
          " />
        <div class="code" :style="width
            ? `top: 39%; font-size: ${codeSize1}px`
            : `top: 180px; font-size: ${defaultCodeSize1}px`
          ">
          <span>{{ num }}</span>
          <span :style="width
              ? `font-size: ${codeSize2}px`
              : ` font-size: ${defaultCodeSize2}px`
            ">{{ typeJiangli == '1' ? '驿马' : '现金' }}</span>
        </div>
      </div>

      <!-- 生成的canvas base64赋值给src的图片 -->
      <img class="img-box" src="" alt="" ref="imgDom" style="display: none" />
    </div>
    <!-- 生成的canvas -->
    <canvas v-if="show" id="myCanvas" :width="width ? width : defaultWidth" :height="height ? height : defaultHeight"
      :style="`width: ${width ? width : defaultWidth}px`"></canvas>
    <div style="height: 130px; flex-shrink: 0"></div>
    <!-- 底部 -->
    <div class="bottom">
      <div>
        <p class="title">分享图文至</p>
      </div>
      <div class="bottom-list">
        <div style="width: 100px" @click="upload(0)">
          <img src="img/weixin.png" alt="" style="width: 32px" />
          <p>微信</p>
        </div>
        <div style="width: 100px" @click="upload(1)">
          <img src="img/pengyouquan.png" alt="" style="width: 32px" />
          <p>朋友圈</p>
        </div>
        <div style="width: 100px" @click="upload(2)">
          <img src="img/qq.png" alt="" style="width: 32px" />
          <p>QQ</p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
  </div>
</template>

<script>
import { rainShare } from "@/api/redPackageRain";
import { upload } from "@/api/upload";
// import { Toast } from "vant";
//获取在线二维码（陈曹宇）
import { getErCode } from "@/api/invitationRegister" // upload
//以上是添加的
import html2canvas from "html2canvas";
export default {
  name: "redPackageRainShare",
  components: {},
  data() {
    return {
      id: null,
      num: null,
      show: true,
      src: null,
      qrSrc:
        "http://121.36.73.162:9000/joolun/1/material/33de764d-bd59-4934-a8a2-8dcfc0d52718.png",
      width: null,
      height: null,
      codeSize1: null,
      codeSize2: null,
      defaultWidth: 288,
      defaultHeight: 461,
      defaultCodeSize1: 19,
      defaultCodeSize2: 20,
      typeJiangli: ''
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.$route.query.num) {
      this.num = this.$route.query.num;
    }
    // 刚进来图片变大
    this.width = document.body.clientWidth;
    // 不变大 但是分享出去的图会糊一点
    // this.width = this.defaultWidth;
    this.height = Math.round(
      (this.width / this.defaultWidth) * this.defaultHeight
    );
    this.codeSize1 = Math.round(
      (this.width / this.defaultWidth) * this.defaultCodeSize1
    );
    this.codeSize2 = Math.round(
      (this.width / this.defaultWidth) * this.defaultCodeSize2
    );
    // console.log("codesize1", this.codeSize1);
    // console.log("codeside2", this.codeSize2);
    //更改二维码获取方法（陈曹宇）：

    this.getCode()

    //以下不动，给你注释掉了
    /* let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isIOS) {
      this.qrSrc = require("../../assets/img/qr/ios.png");
    }
    if (isAndroid) {
      this.qrSrc = require("../../assets/img/qr/android.png");
    } */
  },
  mounted() {
    //这里给你注释掉了
    // this.createCanvas();
    this.typeJiangli = this.$route.query.typeJiangli
    window.redEnvelopeRainShareSuccess = this.redEnvelopeRainShareSuccess;
  },
  methods: {
    //获取二维码的方法写这里了
    getCode() {
      let params = {
        // http://192.168.0.100:8082/login#/login
        content: "http://mall.ydcp315.com/app/#/login",
        width: 200,
        height: 200,
        //  https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdc1533d3aaa355afe4517287ecd6a774d33eb7b0ea38abef29f8c4ad0e9b101e
        logoUrl: "",
        logoWidth: 50,
        logoHeight: 50,
      };
      getErCode(params).then((res) => {
        if (res.data.code === 0) {
          this.qrSrc = res.data.data.downloadUrl;
          // this.numCode = res.data.data.invite_code; //保存验证码，传给原生
          this.$nextTick(() => {
            this.createCanvas();
          })

        } else {
          this.$toast("请求数据失败");
          this.$Toast(res.data.message);
        }
      })
    },
    //以下是你的代码
    createCanvas() {
      var options = {
        // 画布配置项
        useCORS: true,
        dpi: 300,
        scale: 1,
        canvas: document.querySelector("#myCanvas"),
        width: this.width ? this.width : this.defaultWidth,
        height: this.height ? this.height : this.defaultHeight,
      };
      html2canvas(this.$refs.imageDom, options).then((canvas) => {
        this.$refs.imgDom.src = canvas.toDataURL("image/png");
        this.width = null;
        this.height = null;
        this.codeSize1 = null;
        this.codeSize2 = null;
        this.show = false;
      });
    },
    upload(type) {
      if (!this.src) {
        const blob = this.base64ToBlob(this.$refs.imgDom.src);
        const file = this.blobToFile(blob, "share.png");
        let formData = new window.FormData();
        formData.append("file", file);
        formData.append("fileType", "image/png");
        formData.append("dir", "material");
        upload(formData).then((res) => {
          console.log(res, '0000000');
          this.src = res.data.data;
          if (type == 99) {
            this.download();
          } else {
            this.redEnvelopeRainShare(type);
          }
        });
      } else {
        if (type == 99) {
          this.download();
        } else {
          this.redEnvelopeRainShare(type);
        }
      }
    },
    redEnvelopeRainShare(type) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      let obj = {
        title: "Win生活",
        picUrl: this.src,
        content: "有一波红包雨即将来袭",
        // openUrl: `#/redPackageRain?id=${this.id}`,
        openUrl: `#/hongbao`,
        platform: { platformId: type, isDirectShare: "true" },
      };
      if (isIOS) {
        window.webkit.messageHandlers.redEnvelopeRainShare.postMessage(
          JSON.stringify(obj)
        );
      }
      if (isAndroid) {
        window.android.redEnvelopeRainShare(JSON.stringify(obj));
      }
    },

    redEnvelopeRainShareSuccess() {
      rainShare({ redId: this.id }).then((res) => {
        // if (res.status === 200) {
        // Toast("分享成功");
        console.log(res);
        this.$router.replace("redPackageRain?id=" + this.id);//这里分享了要不要存session
        // }
      });
    },
    close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },

    // base64转blob
    base64ToBlob(base64Data) {
      let arr = base64Data.split(","),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);

      while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
      }
      return new Blob([u8Arr], {
        type: fileType,
      });
    },
    // blob转file
    blobToFile(newBlob, fileName) {
      newBlob.lastModifiedDate = new Date();
      newBlob.name = fileName;
      newBlob = new window.File([newBlob], "png");
      return newBlob;
    },

    // 保存下载
    download() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.savePicToPhone.postMessage(this.src);
      }
      if (isAndroid) {
        window.android.savePicToPhone(this.src);
      }
    },
  },
};
</script>
<style lang="less" scoped>
body {
  -webkit-text-size-adjust: 100% !important;
}

.pic {
  height: calc(100% - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  .img-box {
    height: 85%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pic-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    .qr {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .code {
      position: absolute;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      line-height: 1;
      color: #f55d22;
      width: 100%;
      text-align: center;
    }
  }
}

.bottom {
  position: fixed;
  width: 100%;
  height: 130px;
  bottom: 0;
  padding: 10px 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }

  .bottom-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
}
</style>
